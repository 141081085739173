@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p {
  font-family: 'Open Sans', sans-serif;
}

@layer utilities {
}

.react-images__view-image {
  margin: auto;
}

.react-images__footer__count {
  display: none !important;
  opacity: 0;
}

.item-menu-active {
  color: rgb(251 191 36);
}

@media screen and (min-width: 1500px) {
  #home,
  nav,
  section {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (min-width: 1278px) and (max-width: 1350px) {
  #home,
  nav,
  section {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (max-width: 1100px) and (min-width: 1023px) {
  #home,
  nav,
  section {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (max-width: 763px) {
  section:not(#home):not(#fotos):not(#local) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
